<template>
    <div class="">
        <ATable :url="tableUrl" :columns="columns" :searchFormItems="searchFormItems" @operate-button-call-back="OperateButtonCallBack" ref="table">
            <el-button type="primary" @click="Editor()">新增</el-button>
        </ATable>
        <ShoppingEdit @submit="ShoppingEditSubmit"/>
    </div>
</template>


<script>
import ATable from '@/components/table/index'
import ShoppingEdit from './module/shoppingEdit.vue'
import { inject, provide, ref } from 'vue'

import { list, ShopEdit } from '@/api/shopping'
export default {
    components: {ATable, ShoppingEdit},
    setup () {
        const tableUrl = ref(list)
        const elplus = inject('elplus')
        const columns = ref([
            {prop: 'name', label: '名称'},
            {prop: 'price', label: '价格'},
            {prop: 'type', label: '类型', render (text) {
                let label = {1: '食物', 2: '道具', 3: '装扮'}
                return label[text]
            }},
            {prop: 'img', label: '图片', type: 'image'},
            {prop: 'detail', label: '说明'},
            {prop: 'status', label: '状态', render (text) {
                return text == 1 ? '可见' : '不可见'
            } },
            {prop: 'operate', label: '操作', type: 'operate', width: 160, button: [
                {label: '编辑', dispose: 'Editor'}, 
                // {label: '上架', dispose: 'ControlVisible', condition (row) {
                //     return row.status == 2
                // }},
                {label: '不可见', dispose: 'ControlVisible', condition (row) {
                    return row.status == 1
                }}
            ]},
        ])
        const searchFormItems = ref([
            {label: '商品名称', prop: 'name', type: 'input'},
            {label: '商品类型', prop: 'type', type: 'select', options: [{label: '食物', value: 1}, {label: '道具', value: 2}, {label: '装扮', value: 3}]},
        ])
        let editorForm = ref({})
        let shoppingVisible = ref(false)
        let table = ref(null)
        provide('shoppingVisible', shoppingVisible)
        provide('editorForm', editorForm)

        const methods = {
            Editor (row = {}) {
                if (row.id) {
                    editorForm.value = {name: row.name, price: row.price, type: row.type, img: row.img, detail: row.detail, id: row.id}
                } else {
                    editorForm.value = {name: '', price: '', type: 1, img: '', detail: ''}
                }
                shoppingVisible.value = !shoppingVisible.value
            },
            OperateButtonCallBack ({row, fun}) {
                methods[fun](row)
            },
            ControlVisible (row) {
                elplus.confirm(`确定${row.status == 1 ? '下架' : '上架'}该商品吗？`).then(() => {
                    ShopEdit({id: row.id, status: row.status == 1 ? 2 : 1}).then(res => {
                        if (!res.ret) methods.ShoppingEditSubmit()
                    })
                })
            },
            ShoppingEditSubmit () {
                shoppingVisible.value = false
                table.value.SearchSubmit()
            }
        }

        return {
            table,
            tableUrl,
            columns,
            shoppingVisible,
            searchFormItems,
            ...methods
        }
    }
}
</script>

<style lang="scss" scoped>

</style>